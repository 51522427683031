import {SettingOutlined,} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Directual from 'directual-api';
import { useAuth } from '../auth';
import { Alert, BackTop, Empty, Collapse, Card, Col, Grid, Radio, Row, Select, Skeleton} from 'antd';

import { Loader } from '../components/loader/loader';
import { BlockComparedCards } from '../components/blockComparedCards/blockComparedCards';

const brandsData = require("../data/brands.json");

// Getting data from Directual
// Connect to Directual api
// const api = new Directual({apiHost: '/'});
const api = new Directual({apiHost: 'https://api.directual.com', appID: "dfb7c32d-27e0-45ed-a20a-4bf9e1c26e0a"});

const { Option, OptGroup } = Select;
const { Panel } = Collapse;
const { Meta } = Card;
const { useBreakpoint } = Grid;

const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export default function ProductPortfolio () {
  console.log(`ProductPortfolio`);

  const screens = useBreakpoint();

  // API-endpoint details
  const dataStructure = 'pesticides_for_portfolio'
  const endpoint = 'get_product_portfolio'

  // connect authentication context
  const auth = useAuth();

  // Hooks for handling state
  const [payload, setPayload] = useState([]); // API response
  const [pageInfo, setPageInfo] = useState({}); // API response metadata, e.g. number of objects
  const [loading, setLoading] = useState(true); // initial loader
  const [badRequest, setBadRequest] = useState(); // API error message
  const [pageLoading, setPageLoading] = useState(false); // paging loader
  const [pageNum, setPageNum] = useState(0); // Page number, by default = 0
  const [pageSize, setPageSize] = useState(3000); // Page size, bу default = 1000

  const [brandsIdListOfPrimaryPortfolio, setBrandsIdListOfPrimaryPortfolio] = useState([]);
  const [brandsIdListOfАnalyzedPortfolio, setBrandsIdListOfАnalyzedPortfolio] = useState([]);

  const [currentPayloadOfPrimaryPortfolio, setCurrentPayloadOfPrimaryPortfolio] = useState([]);
  const [currentPayloadOfАnalyzedPortfolio, setCurrentPayloadOfАnalyzedPortfolio] = useState([]);
  const [processedPortfolios, setProcessedPortfolios] = useState([]);
  const [lastChangedSelect, setLastChangedSelect] = useState("");
  const [optionsForDisplayingPortfolios, setOptionsForDisplayingPortfolios] = useState({visibility: "all", groupBy: "poisons_combination_group_custom_name", sortBy: "alphabetically"});

  const [open, setOpen] = useState({'primaryPortfolio': false, 'analyzedPortfolio': false, 'protectedObjects': false, 'pesticideTypes': false, 'aims': false})
  const [selectIsDisabled, setSelectIsDisabled] = useState(false); // Флажок для блокировки селектов во время загрузки данных

  const handleChange = (event, data, selectName) => {
    console.log(data)
    var keys = '';
    for (var i = data.length - 1; i >= 0; i--) {
      keys = keys + "," + data[i].key;
    }
    keys = keys.slice(1);
    onChange({selectName: selectName, values: event, keys: keys})
  }

  const handleChangePrimaryPortfolio = (event, data) => {
    handleChange(event, data, 'brands_of_primary_portfolio');
  }

  const handleChangeАnalyzedPortfolio = (event, data) => {
    handleChange(event, data, 'brands_of_analyzed_portfolio');
  }

  // Изменение отображения портфелей
  const handleChangeVisibilityGroupsFromPortfolios = (event) => {
    setOptionsForDisplayingPortfolios({visibility: event.target.value, groupBy: optionsForDisplayingPortfolios.groupBy, sortBy: optionsForDisplayingPortfolios.sortBy})
  }
  const handleChangeGroupPortfoliosBy = (event) => {
    setOptionsForDisplayingPortfolios({visibility: optionsForDisplayingPortfolios.visibility, groupBy: event.target.value, sortBy: optionsForDisplayingPortfolios.sortBy});
  }
  const handleChangeSortPortfoliosBy = (event) => {
    setOptionsForDisplayingPortfolios({visibility: optionsForDisplayingPortfolios.visibility, groupBy: optionsForDisplayingPortfolios.groupBy, sortBy: event.target.value});
  }

  const onChange = (event) => {
    switch (event.selectName)
    { 
      case "brands_of_primary_portfolio":
        setLastChangedSelect("primary");
        setBrandsIdListOfPrimaryPortfolio(event.keys);
        break;
      case "brands_of_analyzed_portfolio":
        setLastChangedSelect("analyzed");
        setBrandsIdListOfАnalyzedPortfolio(event.keys);
        break;
      /*case "protectedObjects":
        setProtectedObjectsValues(event.values)
        setProtectedObjectsIndexes(event.keys)
        break;
      case "pesticideTypes":
        setPesticideTypesValues(event.values)
        setPesticideTypesIndexes(event.keys)
        break;
      case "aims":
        setAimsValues(event.values)
        setAimsIndexes(event.keys)
        break;
      case "moaCombinations":
        setMoaCombinationsValues(event.values)
        setMoaCombinationsIndexes(event.keys)
        break;
      case "chemicalClassesCombinations":
        setChemicalClassesCombinationsValues(event.values)
        setChemicalClassesCombinationsIndexes(event.keys)
        break;
      case "componentsCombinations":
        setComponentsCombinationsValues(event.values)
        setComponentsCombinationsIndexes(event.keys)
        break;*/
      default:
        alert('Default case');
    }
    console.log(`Вызван onChange в ProductPortfolio`)
  }

  const onSelectHandler = (event) => {
    setOpen({'primaryPortfolio': false, 'analyzedPortfolio': false, 'protectedObjects': false, 'pesticideTypes': false, 'aims': false})

    setSelectIsDisabled(true)
  }

  useEffect(() => {
      if (lastChangedSelect != "") {
        setPageLoading(true)
        if (lastChangedSelect == "primary" && brandsIdListOfPrimaryPortfolio.length == 0) {
          setOptionsForDisplayingPortfolios({visibility: "all", groupBy: optionsForDisplayingPortfolios.groupBy, sortBy: "alphabetically"})
          updateProcessedPortfoliosData([])
        } else if (lastChangedSelect == "analyzed" && brandsIdListOfАnalyzedPortfolio.length == 0) {
          setOptionsForDisplayingPortfolios({visibility: "all", groupBy: optionsForDisplayingPortfolios.groupBy, sortBy: "alphabetically"})
          updateProcessedPortfoliosData([])
        } else {
          getData(lastChangedSelect)
        }

      } else {
        console.log("Запрос не Отправлен")
      }
    }, [brandsIdListOfPrimaryPortfolio, brandsIdListOfАnalyzedPortfolio])

  useEffect(() => {
    console.log("Изменились параметры отображения портфелей");
    updateProcessedPortfoliosData()
  }, [optionsForDisplayingPortfolios])

  //Подготовка данных, полученных с сервера для формирования и нужного отображения портфелей
  function updateProcessedPortfoliosData(currentPayload) {
    console.log("Начался процесс подсчёта");
    // console.log(currentPayload);
    
    let primaryPortfolio = currentPayloadOfPrimaryPortfolio;
    let analyzedPortfolio = currentPayloadOfАnalyzedPortfolio;

    if (currentPayload && lastChangedSelect == "primary") {
      setCurrentPayloadOfPrimaryPortfolio(currentPayload);
      primaryPortfolio = currentPayload;
      //Добавляем к данным портфелей параметр "тип портфеля" - основной или анализируемый
      primaryPortfolio.map((product, indx) => {
        primaryPortfolio[indx]["portfolio_type"] = "primary"
      })
    } else if (currentPayload && lastChangedSelect == "analyzed") {
      setCurrentPayloadOfАnalyzedPortfolio(currentPayload);
      analyzedPortfolio = currentPayload;
      //Добавляем к данным портфелей параметр "тип портфеля" - основной или анализируемый
      analyzedPortfolio.map((product, indx) => {
        analyzedPortfolio[indx]["portfolio_type"] = "analyzed"
      })
    } else {}

    let curPayload = primaryPortfolio.concat(analyzedPortfolio);
    curPayload = curPayload.sort(function(a, b){
      return a['pesticide_type_standart_id'] - b['pesticide_type_standart_id']
    });
    let portfolioGroupedByPesticideType = groupBy(curPayload, 'pesticide_type_standart');

    let groupedPayload = [];
    let groupPortfolio = [];
    let curGroupPortfolioProductsCount = {all: 0, primary: 0, analyzed: 0};
    let intersectionGroupsPortfolioProductsCount = 0;

    Object.keys(portfolioGroupedByPesticideType).map((group, groupIndx) => {
        groupedPayload.push({"group_name": group, "group_count": portfolioGroupedByPesticideType[group].length, "group_portfolio": []})
        groupPortfolio = groupBy(portfolioGroupedByPesticideType[group], optionsForDisplayingPortfolios.groupBy);
        Object.keys(groupPortfolio).sort().map((portfolioBlock, portfolioBlockIndx) => {
          groupedPayload[groupIndx]["group_portfolio"].push({"criterion_title": portfolioBlock, "criterion_portfolio_products": {"primary": [], "analyzed": []}});
          groupPortfolio[portfolioBlock].map(product => {
            groupedPayload[groupIndx]["group_portfolio"][portfolioBlockIndx]["criterion_portfolio_products"][product["portfolio_type"]].push({"title": product.name, "pesticide_type_label": product.pesticide_type_standart_id, "description": product.formulation_source, "brands": product.brands_list})
          })
          //Вычисляем сколько у блока портфеля основных и анализируемых продуктов
          curGroupPortfolioProductsCount["primary"] = groupedPayload[groupIndx]["group_portfolio"][portfolioBlockIndx]["criterion_portfolio_products"]["primary"].length;
          curGroupPortfolioProductsCount["analyzed"] = groupedPayload[groupIndx]["group_portfolio"][portfolioBlockIndx]["criterion_portfolio_products"]["analyzed"].length;
          curGroupPortfolioProductsCount["all"] = curGroupPortfolioProductsCount["primary"] + curGroupPortfolioProductsCount["analyzed"];
          //Добавляем параметр группы, указывающий на наличие пересечения в портфелях
          groupedPayload[groupIndx]["group_portfolio"][portfolioBlockIndx]["intersection"] = (curGroupPortfolioProductsCount["primary"] > 0 && curGroupPortfolioProductsCount["analyzed"] > 0) ? true : false;
          intersectionGroupsPortfolioProductsCount = (curGroupPortfolioProductsCount["primary"] > 0 && curGroupPortfolioProductsCount["analyzed"] > 0) ? intersectionGroupsPortfolioProductsCount + 1: intersectionGroupsPortfolioProductsCount;
          groupedPayload[groupIndx]["group_portfolio"][portfolioBlockIndx]["primary_count"] = curGroupPortfolioProductsCount["primary"];
          groupedPayload[groupIndx]["group_portfolio"][portfolioBlockIndx]["analyzed_count"] = curGroupPortfolioProductsCount["analyzed"];
          //Добавляем параметры группы, указывающий на количество основных, анализируемых и общее количество продуктов в ней
          groupedPayload[groupIndx]["group_portfolio"][portfolioBlockIndx]["all_count"] = curGroupPortfolioProductsCount["all"];
        })
        groupedPayload[groupIndx]["intersection_count"] = intersectionGroupsPortfolioProductsCount;
        intersectionGroupsPortfolioProductsCount = 0;

        //Сортировка внутри группы по количеству пестицидов
        if (optionsForDisplayingPortfolios.sortBy == "asc_quantity" || optionsForDisplayingPortfolios.sortBy == "desc_quantity") {
          groupedPayload[groupIndx]["group_portfolio"].sort(function(a, b){
            if (optionsForDisplayingPortfolios.sortBy == "asc_quantity") {
              return a["all_count"] - b["all_count"];
            } else if(optionsForDisplayingPortfolios.sortBy == "desc_quantity") {
              return b["all_count"] - a["all_count"];
            } else {}
          });
        } else {}

        if (optionsForDisplayingPortfolios.sortBy == "portfolios") {
          console.log(groupedPayload[groupIndx]["group_portfolio"])
          groupedPayload[groupIndx]["group_portfolio"] = groupedPayload[groupIndx]["group_portfolio"].filter(item => item["primary_count"] > 0  && item["analyzed_count"] > 0).concat(groupedPayload[groupIndx]["group_portfolio"].filter(item => item["primary_count"] > 0  && item["analyzed_count"] == 0)).concat(groupedPayload[groupIndx]["group_portfolio"].filter(item => item["primary_count"] == 0  && item["analyzed_count"] > 0))
        }
    });
    console.log(groupedPayload);

    setProcessedPortfolios(groupedPayload);
    // console.log(processedPortfolios.filter(item => item["intersection_count"] > 0).length);
    setPageLoading(false);
  }

  // GET-request
  function getData(portfolio) {
    console.log(`Отправлен запрос в endpoint 'get_product_portfolio' ${portfolio}`)
    var brandsListForRequest = (portfolio === "primary") ? brandsIdListOfPrimaryPortfolio : brandsIdListOfАnalyzedPortfolio;
    console.log(brandsListForRequest);

    api
      // Data structure
      .structure(dataStructure)
      // GET request + query params (sessionID, page, pageSize by default)
      .getData(endpoint, { 
        sessionID: auth.sessionID, 
        page: pageNum, 
        pageSize: pageSize,
        brands: brandsListForRequest})
      .then((response) => {
        setPayload(response.payload)
        setPageInfo(response.pageInfo)
        setLoading(false)
        setSelectIsDisabled(false)
        updateProcessedPortfoliosData(response.payload)
        if (brandsIdListOfPrimaryPortfolio.length > 0 && brandsIdListOfАnalyzedPortfolio.length > 0){ 
          setOptionsForDisplayingPortfolios({visibility: optionsForDisplayingPortfolios.visibility, groupBy: optionsForDisplayingPortfolios.groupBy, sortBy: "portfolios"})
        } else{}
        document.querySelector('.ant-select-focused').classList.remove("ant-select-focused")
      })
      .catch((e) => {
        // handling errors
        setLoading(false)
        setPageLoading(false)
        console.log(e.response)
        setBadRequest(e.response.status + ', ' + e.response.data.msg)
      })
  }

  return (
    <div className="product-portfolio-page-wrapper">

      {!screens["lg"] && <h1 className="custom-page-header">Продуктовые портфели</h1>}

      <Row>
      {(brandsIdListOfPrimaryPortfolio.length > 0 || brandsIdListOfАnalyzedPortfolio.length > 0) && !pageLoading &&
          <Col  span={24}>
            <Collapse className="product-portfolio-page-panel">
              <Panel header="Настройки отображения" extra={<SettingOutlined />}>
                {brandsIdListOfPrimaryPortfolio.length > 0 && brandsIdListOfАnalyzedPortfolio.length > 0 && !pageLoading &&
                  <>
                    <div className="ant-radio-group-custom-wrapper">
                      <h3 className={screens["lg"] ? "ant-radio-group-custom-inline-title" : "ant-radio-group-custom-block-title"}>Показывать:</h3>
                      <Radio.Group 
                        defaultValue={optionsForDisplayingPortfolios.visibility} 
                        size="small"
                        onChange={handleChangeVisibilityGroupsFromPortfolios}>
                        <Radio.Button value="all">все</Radio.Button>
                        <Radio.Button value="matching">совпадающие</Radio.Button>
                        <Radio.Button value="different">отличающиеся</Radio.Button>
                      </Radio.Group>
                    </div>
                  </>
                }
                {(brandsIdListOfPrimaryPortfolio.length > 0 || brandsIdListOfАnalyzedPortfolio.length > 0) && !pageLoading &&
                  <>
                    <div className="ant-radio-group-custom-wrapper">
                      <h3 className={screens["lg"] ? "ant-radio-group-custom-inline-title" : "ant-radio-group-custom-block-title"}>Группировать по сочетанию:</h3>
                      <Radio.Group 
                        defaultValue={optionsForDisplayingPortfolios.groupBy}
                        size="small"
                        onChange={handleChangeGroupPortfoliosBy}>
                        <Radio.Button value="poisons_combination_group_custom_name">действующих веществ</Radio.Button>
                        <Radio.Button value="chemical_classes_combination_group_custom_name">химических классов</Radio.Button>
                        <Radio.Button value="mode_of_actions_combination">механизмов действия</Radio.Button>
                      </Radio.Group>
                    </div>

                    <div className="ant-radio-group-custom-wrapper">
                      <h3 className={screens["lg"] ? "ant-radio-group-custom-inline-title" : "ant-radio-group-custom-block-title"}>Cортировать группы по:</h3>
                      <Radio.Group 
                        defaultValue={optionsForDisplayingPortfolios.sortBy}
                        value={optionsForDisplayingPortfolios.sortBy}
                        size="small"
                        onChange={handleChangeSortPortfoliosBy}>
                        <Radio.Button value="alphabetically">алфавиту</Radio.Button>
                        {brandsIdListOfPrimaryPortfolio.length > 0 && brandsIdListOfАnalyzedPortfolio.length > 0 &&
                          <Radio.Button value="portfolios">портфелям</Radio.Button>
                        }
                        <Radio.Button value="asc_quantity">возрастанию количества препаратов</Radio.Button>
                        <Radio.Button value="desc_quantity">убыванию количества препаратов</Radio.Button>
                      </Radio.Group>
                    </div>
                  </>
                }

              </Panel>
            </Collapse>
          </Col>
        }
      </Row>
      <Row gutter={{ 'xs': 8, 'sm': 8, 'md': 16, 'lg': 32, 'xl': 40, 'xxl': 64 }}>
        <Col  span={12}>
          <h2 className="product-portfolio-column-header">Основной портфель</h2>
          
        </Col>
        <Col  span={12}>
          <h2 className="product-portfolio-column-header">Анализируемый портфель</h2>
          
        </Col>
      </Row>
      <Row gutter={{ 'xs': 8, 'sm': 8, 'md': 16, 'lg': 32, 'xl': 40, 'xxl': 64 }}>
        <Col  span={12}>

          {screens["md"] && 
            <Select
              mode="multiple"
              showArrow
              allowClear
              size='large'
              autoFocus={true}
              className='product-portfolio-select'
              placeholder="Выберите бренды"
              onChange={handleChangePrimaryPortfolio}
              notFoundContent="бренды не найдены"
              onSelect={onSelectHandler}
              open={open["primaryPortfolio"]} 
              disabled={selectIsDisabled} 
              onDropdownVisibleChange={(visible) => setOpen({'primaryPortfolio': visible, 'analyzedPortfolio': false, 'protectedObjects': false, 'pesticideTypes': false, 'aims': false})} 
              options={brandsData}
            />
          }
          {!screens["md"] &&
            <Select
              mode="multiple"
              showArrow
              allowClear
              size='large'
              className='product-portfolio-select'
              placeholder="Выберите бренды"
              onChange={handleChangePrimaryPortfolio}
              notFoundContent="бренды не найдены"
              onSelect={onSelectHandler}
              open={open["primaryPortfolio"]} 
              disabled={selectIsDisabled}
              onDropdownVisibleChange={(visible) => setOpen({'primaryPortfolio': visible, 'analyzedPortfolio': false, 'protectedObjects': false, 'pesticideTypes': false, 'aims': false})} 
              options={brandsData}
            />
          }

        </Col>
        <Col  span={12}>
          <Select
            mode="multiple"
            showArrow
            allowClear
            size='large'
            className='product-portfolio-select'
            placeholder="Выберите бренды"
            onChange={handleChangeАnalyzedPortfolio}
            notFoundContent="бренды не найдены"
            onSelect={onSelectHandler}
            open={open["analyzedPortfolio"]}
            disabled={selectIsDisabled}
            onDropdownVisibleChange={(visible) => setOpen({'primaryPortfolio': false, 'analyzedPortfolio': visible, 'protectedObjects': false, 'pesticideTypes': false, 'aims': false})} 
            options={brandsData}
          />
        </Col>
        
      </Row>     

      {pageLoading &&
        <> 
                <Skeleton className="block-compared-cards-sceleton" active />
                <BlockComparedCards type = "loader"/>
        </>}

      {brandsIdListOfPrimaryPortfolio.length == 0 && brandsIdListOfАnalyzedPortfolio.length == 0 &&
        <Alert
          className="product-portfolio-alert"
          message="Выберите бренд производителя"
          description="Выберите из выпадающего списка название одного или нескольких брендов производителей, после чего будет загружен сгруппированный продуктовый портфель. Выберите бренды из другого выпадающего списка, если хотите сравнить портфели между собой. Для удобства вы можете настроить отображение портфелей, используя различные сортировки и группировки."
          type="info"
          showIcon
        />
      }
      
      {!pageLoading &&
        <>
          {processedPortfolios.map(groupItem => 
            <>
            {(optionsForDisplayingPortfolios.visibility == "all" ||  
            (optionsForDisplayingPortfolios.visibility == "matching" && groupItem["group_portfolio"].filter(item => item.intersection).length > 0) ||
            (optionsForDisplayingPortfolios.visibility == "different" && groupItem["group_portfolio"].filter(item => !item.intersection).length > 0)) &&
                <h2 className = "product-portfolio-primary-group-header">{groupItem["group_name"].toUpperCase()}</h2>
            }
            
            {optionsForDisplayingPortfolios.visibility == "all" && 
              groupItem["group_portfolio"].map(criterionItem => 
              <BlockComparedCards type = "content" title = {criterionItem["criterion_title"]} data = {criterionItem["criterion_portfolio_products"]}/>
            )}
            {optionsForDisplayingPortfolios.visibility == "matching" && 
              groupItem["group_portfolio"].filter(item => item.intersection).map(criterionItem => 
              <BlockComparedCards type = "content" title = {criterionItem["criterion_title"]} data = {criterionItem["criterion_portfolio_products"]}/>
            )}

            {optionsForDisplayingPortfolios.visibility == "different" && 
              groupItem["group_portfolio"].filter(item => !item.intersection).map(criterionItem => 
              <BlockComparedCards type = "content" title = {criterionItem["criterion_title"]} data = {criterionItem["criterion_portfolio_products"]}/>
            )}
            </>
          )}
          {optionsForDisplayingPortfolios.visibility == "matching" && processedPortfolios.filter(item => item["intersection_count"] > 0).length == 0 &&
              <>
                <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                <p className="product-portfolio-page-empty-text">Нет ни одного совпадающего блока.</p>
              </>
          }
          {optionsForDisplayingPortfolios.visibility == "different" && processedPortfolios.filter(item => item["intersection_count"] == 0).length == 0 &&
              <>
                <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                <p className="product-portfolio-page-empty-text">Нет ни одного отличающегося блока.</p>
              </>
          }
          <BackTop />
        </>
      }
    </div>
  )
}